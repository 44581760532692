import React, { useEffect, useState } from "react";
import { Button, IconArrow } from "../UI";
import { activateConfig } from "./config";

import cn from "classnames";
import styles from "./ActivateSection.module.scss";
import {useMediaQuery} from "../../hooks/useMediaQuery";

const ActivateSection = () => {
  const isMobile = useMediaQuery("(max-width: 991px)");
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={styles["activate"]} id="installation">
      <div className={cn("wrapper", styles["activate__wrapper"])}>
        <div className={styles["activate__container"]}>
          <h2 className={cn("main-title", styles["activate__title"])}>
            Activate ObiBear Power
          </h2>
          <p className={"main-subtitle"}>
            Protect yourself from cyber threats and predators hiding in the
            digital jungle.
          </p>
          <div className={styles["activate__items"]}>
            {activateConfig.map(({ image, title }, i) => {
              return (
                <React.Fragment key={`fragment-${i}`}>
                  <div
                    className={styles["activate__item"]}
                    key={`activate-item-${i}`}
                  >
                    {image && <img src={`/images/home/${image}`} alt={title} />}
                    <h3>{title}</h3>
                  </div>
                  {i < activateConfig.length - 1 && (
                    <div className={styles["activate__item-arrow"]}>
                      <IconArrow />
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <Button
            className={styles["activate__button"]}
            href="/pricing"
            rel="nofollow"
            external={"true"}
          >
            Try now!
          </Button>
          <img
            className={styles["activate__decor"]}
            src="/images/home/activate-decor.svg"
            alt="Decoration"
            style={{
              transform: `${
                isMobile ? 'translateX(calc(-50% - 105px)) ' : ''
              }translateY(${offsetY * 0.08 - 400}px)`
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default ActivateSection;
