import React, { useRef, useState } from "react";
import { MobileScanSectionConfig } from "./config";
import { IconAndroidLabel, IconArrowLeft, IconArrowRight } from "../UI";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useObserver } from "../../hooks/useObserver";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import cn from "classnames";
import styles from "./MobileScanSection.module.scss";

const MobileScanSection = () => {
  const [pictureSwiper, setPictureSwiper] = useState(null);
  const textSwiperRef = useRef(null);
  const pictureSwiperRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 991px)");

  const textSwiperParams = {
    speed: 500,
    effect: "fade",
    loop: true,
    allowTouchMove: false,
    modules: [EffectFade],
  };

  const pictureSwiperParams = {
    slidesPerView: "auto",
    centeredSlides: false,
    initialSlide: 0,
    speed: 500,
    loop: true,
    spaceBetween: isMobile ? 16 : 30,
    onInit: setPictureSwiper,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    pagination: isMobile
      ? {
          el: ".swiper-pagination",
          clickable: true,
        }
      : false,
    navigation: !isMobile
      ? {
          prevEl: `.js-prev`,
          nextEl: `.js-next`,
          disabledClass: "is-disabled",
        }
      : false,
    modules: [Navigation, Autoplay, Pagination],
  };

  useObserver(
    pictureSwiperRef,
    () => {
      if (pictureSwiper?.autoplay) {
        pictureSwiper.autoplay.start();
      }
    },
    () => {
      if (pictureSwiper?.autoplay) {
        pictureSwiper.autoplay.stop();
      }
    }
  );

  const onSlideChange = () => {
    if (pictureSwiperRef.current && textSwiperRef.current) {
      const pictureSwiper = pictureSwiperRef.current.swiper;
      const textSwiper = textSwiperRef.current.swiper;

      if (
        textSwiper &&
        !textSwiper.destroyed &&
        pictureSwiper &&
        !pictureSwiper.destroyed
      ) {
        textSwiper.slideToLoop(pictureSwiper.realIndex);
      }
    }
  };

  return (
    <section className={styles["mobile-scan"]}>
      <div className={cn("wrapper", styles["mobile-scan__wrapper"])}>
        <div className={styles["mobile-scan__container"]}>
          <Swiper
            className={cn(
              styles["mobile-scan__text-slider"],
              "mobile-scan__text-slider",
            )}
            ref={textSwiperRef}
            {...textSwiperParams}
          >
            {MobileScanSectionConfig.map(({ title, desc, labels }, i) => {
              return (
                <SwiperSlide
                  className={styles["mobile-scan__text-slide"]}
                  key={`text-slide-${i}`}
                >
                  <div className={styles["top"]}>
                    More tools
                    {labels.android && (
                      <span className={styles["label"]}>
                        <IconAndroidLabel />
                      </span>
                    )}
                    {labels.comingSoon && (
                      <span className={styles["label"]}>Coming soon</span>
                    )}
                  </div>
                  <h3>{title}</h3>
                  <p>{desc}</p>
                </SwiperSlide>
              );
            })}
            <div className={cn(styles["navigation"], "navigation")}>
              <div
                className={cn(
                  styles["navigation-btn"],
                  "navigation-btn",
                  "js-prev",
                )}
              >
                <IconArrowLeft />
              </div>
              <div
                className={cn(
                  styles["navigation-btn"],
                  "navigation-btn",
                  "js-next",
                )}
              >
                <IconArrowRight />
              </div>
            </div>
          </Swiper>
          <Swiper
            className={styles["mobile-scan__picture-slider"]}
            ref={pictureSwiperRef}
            onSlideChange={onSlideChange}
            {...pictureSwiperParams}
          >
            {MobileScanSectionConfig.map(({ image, title }, i) => {
              return (
                <SwiperSlide
                  className={styles["mobile-scan__picture-slide"]}
                  key={`picture-slide-${i}`}
                >
                  <img
                    src={`images/home/mobileScan/${image}.jpg`}
                    width="480"
                    height="680"
                    alt={title}
                  />
                </SwiperSlide>
              );
            })}
            <div className="swiper-pagination"></div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default MobileScanSection;
