import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { IconPawRight, IconPawLeft } from "../UI";

import cn from "classnames";
import styles from "./PawsSection.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const PawsSection = () => {
  const pawRefs = useRef([]);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const pawsCount = isMobile ? 6 : 9;

  useEffect(() => {
    pawRefs.current.forEach((paw, index) => {
      gsap.fromTo(
        paw,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          delay: index * 0.2,
          scrollTrigger: {
            trigger: paw,
            start: "bottom bottom",
            toggleActions: "play none none reverse",
          },
        },
      );
    });
  }, []);

  return (
    <section className={styles["paws"]}>
      <div className={cn("wrapper", styles["paws__wrapper"])}>
        <div className={cn(styles["paws__trail"])}>
          {Array.from({ length: pawsCount }).map((_, index) => (
            <div
              className={styles["paws__elem"]}
              ref={(el) => (pawRefs.current[index] = el)}
              key={index}
            >
              {index % 2 === 0 ? <IconPawRight /> : <IconPawLeft />}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PawsSection;
