const navConfig = [
  {
    href: '/privacy',
    text: 'Privacy Policy',
  },
  {
    href: '/terms',
    text: 'Terms and Conditions',
  },
  {
    href: '/cookie',
    text: 'Сookie Policy',
  },
];

export { navConfig };
