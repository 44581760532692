import {IconDarts, IconDoc, IconPrivacy, IconSecure, IconWiFi} from "../UI";

const featuresSliderConfig = [
  {
    image: "slide-feature-1",
    title: "Detect data leaks instantly",
    icon: IconDoc
  },
  {
    image: "slide-feature-2",
    title: "Optimize your device's performance",
    icon: IconDarts
  },
  {
    image: "slide-feature-3",
    title: "Take control of your digital privacy",
    icon: IconPrivacy
  },
  {
    image: "slide-feature-4",
    title: "Stop apps from spying on you",
    icon: IconSecure
  },
  {
    image: "slide-feature-5",
    title: "Stay safe on any Wi-Fi network",
    icon: IconWiFi
  },
];

export { featuresSliderConfig };
