import { useEffect, useRef } from "react";

export const useObserver = (ref, onVisible, onHidden) => {
  const observer = useRef(null);

  useEffect(() => {
    const cb = (entries) => {
      if (entries[0].isIntersecting) {
        if (onVisible) onVisible();
      } else {
        if (onHidden) onHidden();
      }
    };

    if (ref.current && !observer.current) {
      observer.current = new IntersectionObserver(cb);
      observer.current.observe(ref.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
    };
  }, [ref, onVisible, onHidden]);
}