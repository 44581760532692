import React, {useEffect, useState} from 'react';
import cn from "classnames";
import styles from "./HeroSection.module.scss";
import {Button} from "../UI";

const HeroSection = () => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={styles["hero"]}>
      <div className={cn("wrapper", styles["hero__wrapper"])}>
        <div className={styles["hero__container"]}>
          <h1 className={cn(styles["hero__title"], "main-title")}>
            Grizzly-Level Protection for Your Phone
          </h1>
          <p className={cn(styles["hero__subtitle"], "main-subtitle")}>
            A mighty power that hunts down digital threats to ensure your
            device’s safety. It’s more than just protection - it’s a reliable
            digital companion.
          </p>
          <Button
            className={styles["hero__button"]}
            href="/pricing"
            rel="nofollow"
            external={"true"}
          >
            Get started
          </Button>
        </div>
      </div>
      <img
        className={styles["hero__decor"]}
        src="/images/home/hero.svg"
        alt="ObiBear hero"
        style={{ transform: `translateX(-50%) translateY(${offsetY * 0.05}px)` }}
      />
    </section>
  );
};

export default HeroSection;