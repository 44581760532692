const TopFeaturesSectionConfig = [
  {
    video: {
      name: "feature1-video",
      width: 650,
      preview: "feature1"
    },
    title: "Data Breach Checker",
    desc: "Nothing spoils your day like leaked or stolen passwords. With just a sniff, Obi will detect the smallest signs of compromised data and alert you about the threat.",
    button: "Breach Checker"
  },
  {
    video: {
      name: "feature2-video",
      width: 580,
      preview: "feature2"
    },
    title: "Device System Check",
    desc: "Get to the root of the problem! Find out whether your device has been rooted or tampered with. Check for fresh OS and security updates.",
    button: "System Check"
  }
];

export { TopFeaturesSectionConfig };
