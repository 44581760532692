import React, { useRef, useState } from "react";
import { featuresSliderConfig } from "./config";
import { useObserver } from "../../hooks/useObserver";

import cn from "classnames";
import styles from "./FeatureSliderSection.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Controller } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/css";
SwiperCore.use([Controller]);

const FeatureSliderSection = () => {
  const [mainSwiper, setMainSwiper] = useState(null);
  const [childSwiper, setChildSwiper] = useState(null);
  const featureSectionRef = useRef();

  const mainSwiperParams = {
    direction: "vertical",
    speed: 500,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    onInit: setMainSwiper,
    controller: {
      by: "container",
      control: childSwiper,
    },
    modules: [Autoplay],
  };

  const childSwiperParams = {
    direction: "vertical",
    speed: 500,
    loop: true,
    onSwiper: setChildSwiper,
    controller: {
      by: "container",
      control: mainSwiper,
    },
  };

  useObserver(
    featureSectionRef,
    () => {
      if (mainSwiper?.autoplay) {
        mainSwiper.autoplay.start();
      }
    },
    () => {
      if (mainSwiper?.autoplay) {
        mainSwiper.autoplay.stop();
      }
    }
  );

  return (
    <section className={styles["feature-slider"]} ref={featureSectionRef}>
      <div className={cn("wrapper", styles["feature-slider__wrapper"])}>
        <div className={styles["feature-slider__container"]}>
          <Swiper
            className={styles["feature-slider__main"]}
            {...mainSwiperParams}
          >
            {featuresSliderConfig.map(({ image }, i) => {
              return (
                <SwiperSlide
                  className={styles["feature-slider__main-slide"]}
                  key={`features-slide-${i}`}
                >
                  <picture>
                    <source
                      srcSet={`/images/home/features/${image}_mob.jpg`}
                      media="(max-width: 991px)"
                    />
                    <img
                      src={`/images/home/features/${image}.jpg`}
                      alt={`features-slide-${i}`}
                    />
                  </picture>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            className={styles["feature-slider__children"]}
            {...childSwiperParams}
          >
            {featuresSliderConfig.map(({ title, icon: Icon }, i) => {
              return (
                <SwiperSlide
                  className={styles["feature-slider__children-slide"]}
                  key={`features-slide-title-${i}`}
                >
                  <div className={styles["feature-slider__icon"]}>
                    {Icon && <Icon />}
                  </div>
                  <h3>{title}</h3>
                </SwiperSlide>
              );
            })}
            <div className={styles["feature-slider__circle"]}></div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default FeatureSliderSection;
