import React, { useRef, useState } from "react";
import { TopFeaturesSectionConfig } from "./config";
import { useMediaQuery } from "../../hooks/useMediaQuery";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import cn from "classnames";
import styles from "./TopFeaturesSection.module.scss";

const TopFeaturesSection = () => {
  const mainSwiperRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const mainSwiperParams = {
    speed: 500,
    effect: "fade",
    loop: true,
    allowTouchMove: false,
    modules: [EffectFade],
  };

  const slideTo = (index) => {
    mainSwiperRef.current.swiper.slideTo(index);
  };

  const handleLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <section className={styles["top-features"]} id="learn">
      <div className={cn("wrapper", styles["top-features__wrapper"])}>
        <div className={styles["top-features__container"]}>
          <Swiper
            className={styles["top-features__slider"]}
            {...mainSwiperParams}
            ref={mainSwiperRef}
          >
            {TopFeaturesSectionConfig.map(({ title, desc, video }, i) => {
              return (
                <SwiperSlide
                  className={styles["top-features__slide"]}
                  key={`top-features-slide-${i}`}
                >
                  <div className={styles["video-wrap"]}>
                    <div className={styles["video-block"]}>
                      {!isVideoLoaded && (
                        <img
                          className={styles["image-preview"]}
                          src={`/images/home/video/${video.preview}.png`}
                          alt={video.name}
                        />
                      )}
                      <video
                        className={styles["video"]}
                        width={video.width}
                        autoPlay
                        muted
                        loop
                        playsInline
                        preload="auto"
                        onLoadedData={handleLoadedData}
                        style={{ display: isVideoLoaded ? "block" : "none" }}
                      >
                        <source
                          src={`/images/home/video/${isMobile ? video.name + "-mob.mp4" : video.name + ".mp4"}`}
                          type="video/webm"
                        />
                        <source
                          src={`/images/home/video/${isMobile ? video.name + "-mob.mp4" : video.name + ".mp4"}`}
                          type="video/mp4"
                        />
                      </video>
                    </div>

                    <div className={styles["buttons_wrap"]}>
                      {TopFeaturesSectionConfig.map(({ button }, j) => {
                        return (
                          <button
                            className={cn({
                              [styles["is-active"]]: j === i,
                            })}
                            key={`top-features-button-${j}`}
                            onClick={() => slideTo(j)}
                          >
                            {button}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles["text-wrap"]}>
                    <div className={styles["top"]}>Top Features</div>
                    <h3>{title}</h3>
                    <p>{desc}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default TopFeaturesSection;
