const SecurityCtaConfig = [
  {
    image: "security-cta-1.svg",
    title: "A trusted, time-tested digital protector",
  },
  {
    image: "security-cta-2.svg",
    title: "Instant alerts about hidden threats",
  },
  {
    image: "security-cta-3.svg",
    title: "Tips & tricks on how to secure your data",
  },
  {
    image: "security-cta-4.svg",
    title: "24/7 real-time protection",
  }
];

export { SecurityCtaConfig };