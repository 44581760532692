const plansConfig = [
  {
    name: "Premium",
    isMostPopular: true,
    periods: [
      {
        code: "OBPM",
        title: "1 month",
        isActive: true,
        price: {
          current: '£37.79',
          full: '£53.99'
        }
      },
      {
        code: "OBP3",
        title: "3 months",
        isActive: true,
        price: {
          current: '£21.46',
          full: '£30.66'
        }
      },
      {
        code: "OBP6",
        title: "6 months",
        isActive: true,
        price: {
          current: '$13.42',
          full: '£19.17'
        }
      },
      {
        code: "OBPY",
        title: "12 month",
        isActive: true,
        price: {
          current: '£8.75',
          full: '£12.50'
        }
      }
    ],
    features: [
      'Detect spyware and malicious apps',
      'Optimize device performance',
      'Secure your Wi-Fi connection',
      'Protect personal data from data breaches',
      'Maximize your privacy with expert guides '
    ]
  },
  {
    name: "Extreme",
    isMostPopular: false,
    periods: [
      {
        code: "OBEM",
        title: "1 month",
        isActive: true,
        price: {
          current: '£39.99',
          full: '£57.13'
        }
      },
      {
        code: "OBE3",
        title: "3 months",
        isActive: true,
        price: {
          current: '£27.99',
          full: '£39.99'
        }
      },
      {
        code: "OBE6",
        title: "6 months",
        isActive: true,
        price: {
          current: '£27.99',
          full: '£39.99'
        }
      },
      {
        code: "OBEY",
        title: "12 month",
        isActive: true,
        price: {
          current: '£19.99',
          full: '£28.56'
        }
      }
    ],
    features: [
      '<span>All Premium</span> features included',
      'Secure your social media accounts',
      'Detect hidden cameras in any space',
      'Prevent identity theft with real-time alerts',
      'Block dangerous websites and online threats'
    ]
  }
];

export { plansConfig };
