const activateConfig = [
  {
    image: "activate-1.svg",
    title: "Install the ObiBear app",
  },
  {
    image: "activate-2.svg",
    title: "Choose your bear-strong protection plan",
  },
  {
    image: "activate-3.svg",
    title: "Let Obi sniff out all the threats",
  }
];

export { activateConfig };
