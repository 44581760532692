import React from 'react';
import { Helmet } from "react-helmet-async";

import ActivateSection from "../components/ActivateSection";
import FAQSection from "../components/FAQSection";
import HeroSection from "../components/HeroSection";
import FeatureSliderSection from "../components/FeatureSliderSection";
import SecurityCtaSection from "../components/SecurityCtaSection";
import MobileScanSection from "../components/MobileScanSection";
import TopFeaturesSection from "../components/TopFeaturesSection";
import PawsSection from "../components/PawsSection";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>ObiBear – Reliable Security App for Your Phone</title>
        <meta
          name="description"
          content="The best paws for the job! ObiBear provides advanced features to protect you against malware, phishing, and other cyber threats."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <HeroSection />
      <SecurityCtaSection />
      <TopFeaturesSection />
      <PawsSection />
      <MobileScanSection />
      <FeatureSliderSection />
      <ActivateSection />
      <FAQSection />
    </>
  );
};

export default Home;
